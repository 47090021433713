@font-face {
  font-family: 'rubikmedium';
  src: url('../fonts/rubik-medium-webfont.woff2') format('woff2'), url('../fonts/rubik-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'luckiest_guyregular';
  src: url('../fonts/luckiestguy-webfont.woff2') format('woff2'), url('../fonts/luckiestguy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Font family
$font-stack: 'Roboto', sans-serif; // Html
$font-cursive: 'luckiest_guyregular', cursive;
$font-title: 'rubikmedium', sans-serif;

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity .1s ease-in-out;
}
// Google font ballback


// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0;
}
p {
  margin-bottom: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-family: $font-cursive;
  font-size: 70px;
  line-height: 70px;
  .small {
    color: $ye-a;
    font-size: 50px;
    display: block;
    line-height: 1.2em;
    margin-top: 5px;
    letter-spacing: 0.05em;
    font-weight: 400;
  }
}
.h-like-b {
  font-family: $font-title;
  font-size: 42px;
  letter-spacing: -.025em;
  line-height: 35px;
}
.h-like-c {
  font-size: 16px;
}
.h-like-d {
  font-family: $font-title;
  font-size: 15px;
}
.h-like-e {
  font-family: $font-title;
  font-size: 18px;
  line-height: 1.2em;
}
.h-like-f {
  font-family: $font-title;
  font-size: 26px;
  line-height: 1.2em;
}
.h-like-g {
  font-family: $font-title;
  font-size: 20px;
  line-height: 1.2em;
}

@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {
    font-size: 55px;
    line-height: 55px;
  }
  .h-like-b {
    font-size: 38px;
  }
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 50px;
    line-height: 50px;
  }
  .h-like-b {
    font-size: 35px;
    line-height: 1.2em;
  }
  .h-like-c {
    font-size: 15px;
  }
  .h-like-f {
    font-size: 24px;
  }
}
@include media-breakpoint-down(xs) {
  .h-like-b {
    font-size: 28px;
  }
  .h-like-a {
    font-size: 45px;
    line-height: 45px;
  }
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {
  font-size: 16px;
}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {
    font-size: 15px;
  }
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
