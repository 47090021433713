// Footer

#footer {
	margin-top: 100px;
	padding: 40px 0;
	background-color: #444;
	color: $wh-a;

	.row-copyright {
		border-top: 1px solid tint($bl-a, 70%);
		padding-top: 15px;
		margin-top: 40px;
	}

	.container {
		position: relative;
	}

	.bulb-footer {
		position: absolute;
		top: -110px;
		right: 230px;
	}

	a.h-like-d {
		&:hover {
			text-decoration: underline;
		}
	}

	.day-align {
		display: flex;
		span:first-child {
			width: 75px;
		}
		span:last-child {
			width: calc(100% - 75px);
		}
	}

	@include media-breakpoint-down(md) {
		.bulb-footer {
			width: 80px;
			right: 150px;
			top: -100px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 25px 0;
		margin-top: 50px;
		.bulb-footer {
			display: none;
		}

		.row-copyright {
			padding-top: 10px;
			margin-top: 20px;
		}
	}


	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(xs) {
		.day-align {
			justify-content: center;
			span:last-child {
				width:  110px;
			}
		}
		margin-top: 0;
		text-align: center;
	}
}
