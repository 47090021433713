// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(md) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure
@include media-breakpoint-down(sm) {
	#nav-mobile-trigger {
		background-color: $or-a;
		width: 65px;
		height: 65px;
		border-radius: 100%;
		position: absolute;
		top: 19px;
		right: 20px;
		z-index: 15;
	}
}




#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	background-color: $wh-a;
	transition: all 0.2s ease-in-out;

	.nav-mobile-search {
		display:  none;
	}

	@include media-breakpoint-down(sm) {
		.nav-mobile-search {
			display: block;
			border-radius: 100%;
			width: 65px;
			height: 65px;
			background-color: $or-a;
			position: absolute;
			top: 19px;
			right: 100px;
			.material-icons {
				color: $wh-a;
				font-size: 45px;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		.logo {
			display: block;
			width: 200px;
		}
	}

	.container {
		position: relative;
	}
}

.header-top {
	box-shadow: 0 0 10px rgba(0,0,0, .2);
	.sitename {
		font-size: 15px;
		font-weight: 500;
	}
	@include media-breakpoint-down(sm) {
		.sitename {
			font-size: 13px;
		}
	}
}

.search-wp {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $wh-a;

	@include media-breakpoint-down(sm) {
		height: 60px;
		z-index: 40;
		border-radius: 0;
		background-color: transparent;
		position: relative;
		width: calc(100% - 30px);
		margin: 15px;
		input {
		}
		.actions-wp {
		}
	}


	.actions-wp {
		background-color: $ye-a;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 30px;
		height: 100%;
		padding: 0 10px;
		display: flex;
		align-items: center;
		.material-icons {
			font-size: 26px;
		}
	}

	input {
		width: 100%;
		height: 100%;
		border: 3px solid $ye-a;
		padding: 0 110px 0 25px;
		border-radius: 30px;
		font-size: 16px;
		font-weight: 500;
		&::placeholder {
			opacity: .5;
			font-style: italic;
		}
		&:focus {
			outline: none;
		}
	}
}


#nav-main {
	display: flex;
	background-color: $or-a;
	border-radius: 30px;
	align-items: center;
	padding: 0 50px 0 15px;
	position: relative;


	.item {
		flex-grow: 1;
		&.item-search {
			&:not(.open){
				transition: all 0.2s ease-in-out;
				position: absolute;
				right: 15px;
				top: 50%;
				margin-top: -16px;
				&:hover {
					transform: scale(.9);
				}
			}
		}
	}

	.link {
		display: block;
		color: $wh-a;
		font-weight: 700;
		padding: 15px 0 15px 15px;
		transition: all 0.2s ease-in-out;
	}

	.link:focus,
	.link:hover,
	.link.active {
		color: shade($or-a, 50%);
	}

	.nav-sub {
		.item-sub {
			&:not(:first-child) {
				margin-top: 7.5px;
			}
		}

		.link-sub {
			display: block;
			line-height: 1.2em;
			font-size: 13px;
			font-weight: 500;
            color: $wh-a;
		}

		.link-sub:focus,
		.link-sub:hover,
		.link-sub.active {
			color: $or-a;
		}
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px;
		margin: 15px 0;
		.link {
			padding: 5px 0;
			text-align: center;
			font-size: 16px;
		}
		.item.item-search {
			display: none;
		}
		.nav-sub {
			text-align: center;
			background-color: shade($ye-a, 5%);
			border-radius: 10px;
			padding: 15px;
			margin: 5px 0;
			.link-sub {
				font-size: 14px;
			}
		}
	}
}
// Navigation :: Sub

.nav-sub {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	height: auto;
	opacity: 1;
	background-color: $ye-a;
}

li[data-navsub] {
	position: relative;
}
@include media-breakpoint-up(md) {
	li[data-navsub].focus > .nav-sub {
		visibility: visible;
		display: block;
		top: 57.5px;
		padding: 15px 20px;
		height: auto;
		opacity: 1;
	}

	.nav-sub {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		overflow: hidden;
		height: 0;
		width: 200px;
		border-radius: 15px;
		opacity: 0;
		background-color: $or-a;
		transition: all 0.1 ease-in-out;
		box-shadow: 0 3px 5px -2px rgba(0,0,0,0.2);
	}
}

.header-page {
	padding: 100px 0;
	background-attachment: fixed;
	background-image: url('../images/illustrations/header-page.jpg');
	background-position: center;
    background-repeat: no-repeat;
	position: relative;

	.title {
		position: relative;
		color: $wh-a;
		font-size: 60px;
		font-family: $font-cursive;
		letter-spacing: .05em;
		text-align: center;
		z-index: 3;
	}

	&:after {
		content: "";
		width: 100%;
		z-index: 1;
		height: 100%;
		backdrop-filter:  blur(2px) sepia(1);
		background-color: rgba(0, 107, 225, .5);
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
	}
	@include media-breakpoint-down(lg) {
		padding: 75px 0;
		.title {
			font-size: 50px;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 50px 0;
		.title {
			font-size: 40px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0;
		.title {
			font-size: 30px;
		}
		&:after {
			background-color: $bl-a;
		}
	}

}
