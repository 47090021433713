// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #161616;
$wh-a: #FFFFFF;
$gy-a: #F4F7FC;
$gy-b: shade($gy-a, 50%);
$bl-a: #006BE1;
$ye-a: #FCCC6C;
$or-a: #FE8B00;

// Color
.c-bk-a {
  color: $bk-a;
}
.c-wh-a {
  color: $wh-a;
}
.c-gy-a {
  color: $gy-a;
}
.c-gy-b {
  color: $gy-b;
}
.c-ye-a {
  color: $ye-a;
}
.c-bl-a {
  color: $bl-a;
}
.c-or-a {
  color: $or-a;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}
.bg-wh-a {
  background-color: $wh-a;
}
.bg-gy-a {
  background-color: $gy-a;
}
.bg-gy-b {
  background-color: $gy-b;
}
.bg-ye-a {
  background-color: $ye-a;
}
.bg-bl-a {
  background-color: $bl-a;
}
.bg-or-a {
  background-color: $or-a;
}
