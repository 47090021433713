// Body
body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}
html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: .65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: .8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all .2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 400px;
	background-color: #e5e3df;
	border-radius: 50px 0 50px 0;
	overflow: hidden;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}

.activity-container {
	position: relative;
	.bulb-img {
		position: absolute;
		top: 20px;
		right: -50px;
		@include media-breakpoint-down(xs) {
			display: none;
		}
	}
}

.activity-item {
	background-color: $wh-a;
	height: 100%;
	border-radius: 50px 0 50px 0;
	overflow: hidden;
	.link {
		padding: 20px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		height: 100%;
		&:hover {
			img {
				transform: scale(1.1);
			}
			.btn-a {
				color: $or-a;
				background-color: $wh-a;
			}
		}
	}
	p {
		font-size: 12px;
	}
	figure {
		display: block;
		overflow: hidden;
		img {
			transition: all .2s ease-in-out;
		}
	}
}

.news-item {
	background-color: $wh-a;
	transition: all .2s ease-in-out;
	box-shadow: 4px 4px 15px rgba(0, 0, 0, 0);

	&:hover {
		box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
	}

	.link {
		padding: 20px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		height: 100%;
		figure {
			display: block;
			overflow: hidden;
			border-radius: 10px;
			img {
				transition: all .2s ease-in-out;
			}
		}
		&:hover {
			img {
				transform: scale(1.1);
			}

		}
	}
}

.social-section {
	border-radius: 30px 0 30px 0;
	padding: 20px;
	background-color: $gy-a;
}

.radius {
	border-radius: 50px 0 50px 0;
}

.news-separator {
	background-color: $gy-a;
	height: 2px;
	border: 0;
	margin-bottom: 50px;
	box-shadow: 0;

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}
}

.nav-button {
	background-color: $bl-a;
	display: block;
	height: 100%;
	border-radius: 10px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: .05em;
	color: $wh-a !important;
	font-weight: 500;
	text-align: center;
	padding: 15px 10px;
	border: 3px solid $bl-a;
	transition: all .2s ease-in-out;

	&:hover {
		transform: scale(.9);
	}

	&.active {
		background-color: $wh-a;
		font-weight: 700;
		color: $bl-a !important;
	}

	@include media-breakpoint-down(sm) {
		padding: 12px 8px;
		font-size: 12px;
	}
}

#sticky-wp {
	z-index: 10;
}

.category {
	background-color: $bl-a;
	display: inline-block;
	padding: 5px 8px 3px;
	transition: all .2s ease-in-out;
	text-transform: uppercase;
	border-radius: 4px;
	font-size: 9px;
	line-height: 1em;
	color: $wh-a;
	border: 2px solid $bl-a;
	font-weight: 500;

	&.large {
		padding: 5px 8px 3px;
		font-size: 11px;
	}
}

.list-item {
	background-color: $wh-a;


	.img-wp {
		display: block;
		border-radius: 50px 0 50px 0;
		overflow: hidden;
		transform: translateZ(0);
	}

	.title {
		font-family: $font-title;
		font-size: 22px;
		margin-top: 7.5px;
		line-height: 1.25em;
		color: $bl-a;

		@include media-breakpoint-down(lg) {
			font-size: 20px;
		}
	}

	.img-fluid {
		transition: all .2s ease-in-out;
	}

	.link {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;

		&:hover {
			.img-fluid {
				transform: scale(1.1);
			}

			.category {
				background-color: $wh-a;
				color: $bl-a;
			}
		}
	}
}

.nav-list {
	a {
		display: block;
		position: relative;
		text-align: left;
		color: $bl-a;
		padding-left: 20px;
		transition: all .2s ease-in-out;

		&:hover {
			padding-left: 25px;
		}

		.material-icons {
			font-size: 16px;
			position: absolute;
			top: 1px;
			left: 0;
		}
	}
}

.list-cook {
	margin-top: 15px;
	background-color: $gy-a;
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding: 10px 15px;

	.text {
		padding-left: 10px;
		color: $bl-a;
		line-height: 1.1em;
		margin-bottom: 0;
		font-weight: 500;
	}
}

.mosaique {
	position: relative;
	img {
		width: 100%;
	}
	&-ampoule,
    &-ampoule2,
	&-image {
		position: absolute;
		top: 0;
		left: 0;
	}
    &-ampoule,
    &-ampoule2 {
		z-index: 0;
	}
    &-trait {
		z-index: 1;
		position: relative;
	}
    &-image  {
		z-index: 2;
	}

    &-ampoule2 {
        animation: anim-ampoule 2s infinite linear;
    }
    @keyframes  anim-ampoule {
        0% { opacity: 1 }
        50% { opacity: 0 }
        100%{ opacity: 1}
    }
}

.label {
    position: absolute;
    bottom: -50px;
    left: 180px;

    @include media-breakpoint-down(lg) {
        img {
            width: 140px;
        }
    }
    @include media-breakpoint-down(md) {
        left: initial;
        right: 10px;
    }
    @include media-breakpoint-down(sm) {
        bottom: -30px;
        img {
            width: 100px;
        }
    }
    @include media-breakpoint-down(xs) {
        bottom: -50px;
    }
}


.themes {
    margin-top: 20px;
    transform: rotate(-3deg);
    &-line {
        display: inline-block;
        padding: 2px 10px;
        background-color: $or-a;
        font-size: 20px;
        color: $wh-a;
    }
}
