// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url('../images/icons/sprite.png');
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite.back-top {
	width: 50px;
	height: 50px;
}

.sprite.back-top {
	background-position: 0 0;
}

.sprite.search,
.sprite.close {
	width: 30px;
	height: 30px;
}

.sprite.search {
	background-position: -50px 0;
}

.sprite.close {
	opacity: 1;
	background-position: -50px -30px;
}

.sprite.see-more {
	width: 20px;
	height: 20px;
	background-position: -80px 0;
}

.sprite.facebook-large,
.sprite.instagram-large,
.sprite.tiktok-large,
.sprite.youtube-large {
	width: 40px;
	height: 40px;
	transition: all .2s ease-in-out;
	&:hover {
		transform: scale(.9);
	}
}

.sprite.facebook-large {
	background-position: -100px 0;
}

.sprite.tiktok-large {
	background-position: -140px 0;
}

.sprite.youtube-large {
	background-position: -180px 0;
}

.sprite.instagram-large {
	background-position: -220px 0;
}

.sprite.facebook-small,
.sprite.instagram-small,
.sprite.tiktok-small,
.sprite.youtube-small {
	width: 30px;
	height: 30px;
}

.sprite.facebook-small {
	background-position: -100px -60px;
}

.sprite.tiktok-small {
	background-position: -130px -60px;
}

.sprite.youtube-small {
	background-position: -160px -60px;
}

.sprite.instagram-small {
	background-position: -190px -60px;
}
