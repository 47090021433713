// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-social {
	display: flex;
	align-items: center;
	border-radius: 30px;
	padding: 5px 15px 5px 10px;
	background-color: $wh-a;
	font-size: 11px;
	transition: all .2s ease-in-out;
	.sprite {
		transition: all .2s ease-in-out;
	}
	&:hover {
		background-color: rgba(255,255,255, .7);
		.sprite {
			transform: scale(.9);
		}
	}
}

.btn-a {
	display: inline-block;
	transition: all .2s ease-in-out;
	background-color: $or-a;
	padding: 10px 20px;
	border-style: solid;
	border-width: 1px;
	font-weight: 500;
	text-align: center;
	line-height: 1em;
	border-radius: 30px;
	font-size: 12px;

	&.large {
		padding: 10px 20px;
	}

	&.small {
		padding: 6px 14px;
	}

	&.orange {
		border-color: $or-a;
		background-color: $or-a;
		color: $wh-a;
		&:focus,
		&:hover {
			color: $or-a;
			background-color: $wh-a;
		}
	}
	&.grey {
		border-color: $gy-a;
		background-color: $gy-a;
		color: $bl-a;
		&:focus,
		&:hover {
			color: $wh-a;
			background-color: $bl-a;
		}
	}
	&.blue {
		border-color: $bl-a;
		background-color: $bl-a;
		color: $wh-a;
		&:focus,
		&:hover {
			color: $bl-a;
			background-color: $gy-a;
		}
	}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 36px;
	height: 26px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all .2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 5px;
		border-radius: 10px;
		width: 100%;
		background: $wh-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all .2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 11px;
	}

	span:nth-child(4) {
		top: 22px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all .2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}
